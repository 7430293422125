main.main.single-events
  header.page-header
    min-height: 480px
    @include background
    @include media-breakpoint-up(md)
      min-height: 720px

  section.intro,
  section#featured
    background: rgb(0,53,149)
    background: linear-gradient(45deg, rgba(0,53,149,1) 35%, rgba(108,209,222,1) 100%)

  section#services
    .container
      .row
        .col-6
          .card
            .card-header
              img.img-fluid
                max-height: 72px




  section#location-details
    .container
      .row

        &:nth-of-type(3)
          .col-lg-4
            .card
              .card-body#map
                min-height: 300px

          .col
            .card
              .card-body
                ul.grid
                  list-style: none
                  padding: 0
                  display: grid
                  -webkit-display: grid
                  grid-template-rows: auto
                  grid-template-columns: repeat(2, 1fr)
                  grid-column-gap: 8px
                  li
                    .card
                      padding-left: 1.5rem
                      position: relative
                      i, svg
                        position: absolute
                        left: 0px
                        top: 11px
