main.main#home
  header.page-header
    min-height: 400px
    @include media-breakpoint-up(lg)
      min-height: 760px
    @include background
    .container
      .row
        .col-lg-6
          .card
            .card-header
              h2
                @include media-breakpoint-up(lg)
                  font-size: 3.25rem
            .card-body
              p.text-white
                @include media-breakpoint-up(md)
                  font-size: 1.25rem

  section#intro
    background: rgb(0,53,149)
    background: linear-gradient(45deg, rgba(0,53,149,1) 25%, rgba(108,209,222,1) 100%)
    .container
      .row
        &:first-of-type
          .col
            margin-top: -30px
            @include media-breakpoint-up(lg)
              margin-top: -60px
            .card
              .card-body
                @include media-breakpoint-up(lg)
                  padding: 60px 200px
                h2
                  // background: linear-gradient(45deg, rgba(0,53,149,1) 0%, rgba(108,209,222,1) 100%)
                  // -webkit-background-clip: text
                  // -webkit-text-fill-color: transparent
                  line-height: 3rem
        &:nth-of-type(2)
          .col-12
            .card
              .card-footer
                a.btn
                  position: relative
                  padding-right: 28px
                  background-color: #FFD133
                  svg
                    position: absolute
                    @include calc(top, '50% - 5px')
                    right: 12px
                    transition: all .2s ease-in-out
                    &:last-of-type
                      opacity: 0
                      visibility: hidden
                  &:hover
                    background: $white
                    svg
                      &:first-of-type
                        opacity: 0
                        visibility: hidden
                        transform: translateX(4px)
                      &:last-of-type
                        opacity: 1
                        visibility: visible
                        transform: translateX(4px)

  section#sustainability
    background: linear-gradient(to bottom, #ffffff, #c2e3d0)
    border-bottom: 1px solid #adb5bd
    .container
      .row:first-of-type
        .col-md-8
          p
            &:last-of-type
              max-width: 240px
              @include media-breakpoint-up(sm)
                max-width: 100%

    img
      bottom: 0
      right: 0
      max-height: 188px
      @include media-breakpoint-up(md)
        max-height: 256px

  section#news
    .container
      &:nth-of-type(2)
        .row
          .col
            .card
              a
                top: 0
                left: 0

              .card-body
                a
                  h3
                    color: $primary
                    transition: all .4s cubic-bezier(.5,0,0,1)
                  &:hover h3
                    color: $penta-aqua
