.breakpoint
  position: fixed
  z-index: 99999
  bottom: 0
  left: 0
  background-color: skyblue
  color: $dark
  text-align: center
  transition: all .4s ease-in-out
  @include media-breakpoint-up(sm)
    background-color: darken(skyblue, 10%)
  @include media-breakpoint-up(md)
    background-color: darken(skyblue, 20%)
  @include media-breakpoint-up(lg)
    background-color: darken(skyblue, 30%)
  @include media-breakpoint-up(xl)
    background-color: darken(skyblue, 40%)
  @include media-breakpoint-up(xxl)
    background-color: darken(skyblue, 50%)
  p
    font-size: 14px
    line-height: 20px
    color: $dark
    @include media-breakpoint-up(lg)
      color: $light
    &::after
      display: block
      content: "XS"
      @include media-breakpoint-up(sm)
        content: "SM"
      @include media-breakpoint-up(md)
        content: "MD"
      @include media-breakpoint-up(lg)
        content: "LG"
      @include media-breakpoint-up(xl)
        content: "XL"
      @include media-breakpoint-up(xxl)
        content: "XXL"
