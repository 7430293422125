main.main#careers,
main.main#carrieres

  section#careers-details
    .container
      .row
        display: grid
        grid-template-rows: repeat(3, 1fr)
        grid-template-columns: repeat(2, 1fr)
        grid-row-gap: 80px
        grid-column-gap: 28px

        .col

          &:nth-of-type(odd)
            @include background

          &:first-of-type
            grid-area: 1 / 1 / 2 / 2
          &:nth-of-type(2)
            grid-area: 1 / 2 / 2 / 3
          &:nth-of-type(3)
            grid-area: 2 / 2 / 3 / 3
          &:nth-of-type(4)
            grid-area: 2 / 1 / 3 / 2
          &:nth-of-type(5)
            grid-area: 3 / 1 / 4 / 2
          &:nth-of-type(6)
            grid-area: 3 / 2 / 4 / 3
