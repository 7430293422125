main.main#compliance

  header.page-header
    background-image: url('/assets/img/headers/data-chains.jpg')

  section.compliance__item
    &.bg-white
      .container
        .row
          .col-lg-6
            .card
              .card-footer
                ul.list-unstyled
                  li
                    a
                      background-color: $light

    &.bg-light
      .container
        .row
          .col-lg-6
            .card
              .card-footer
                ul.list-unstyled
                  li
                    a
                      background-color: $white
