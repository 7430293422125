main.main.single-regions

  header.page-header
    min-height: 480px
    @include background
    @include media-breakpoint-up(md)
      min-height: 720px

  section.intro,
  section#featured
    background: rgb(0,53,149)
    background: linear-gradient(45deg, rgba(0,53,149,1) 35%, rgba(108,209,222,1) 100%)

  section#services
    .container
      .row
        .col-6
          .card
            .card-header
              img.img-fluid
                max-height: 72px




  section#highlights
    .container

      &:nth-of-type(2)
        .row
          .col-lg-5
            .card
              .card-body
                svg.fa-quote-right
                  background: rgb(0,53,149)
                  background: linear-gradient(45deg, rgba(0,53,149,1) 35%, rgba(108,209,222,1) 100%)
                  width: 30px
                  height: 30px
                  border: 2px solid $gray-400
                  transform: translateY(-50%)

  section#country-clients
    .container
      .row
        ul.col-lg-9
          display: grid
          grid-auto-rows: 1fr
          grid-template-columns: repeat(2, 1fr)
          @include media-breakpoint-up(md)
            grid-template-columns: repeat(3, 1fr)
          @include media-breakpoint-up(xl)
            grid-template-columns: repeat(4, 1fr)
