// section#topbar
  // z-index: 999
  .container
    .navbar-collapse
      ul.navbar-nav
        li.nav-item
          &#i18n
            // background: $gray-300

          a.nav-link
            outline: 0
            color: $secondary
            &.dropdown-toggle
              &::after
                display: none
              i, svg
                transform: translateY(2px)
                transition: all .2s ease-in-out
              &[aria-expanded="true"]
                i, svg
                  transform: translateY(2px) rotate(90deg)

        li.nav-item.dropdown
          .dropdown-menu
            // transform: translateY(11px)
            left: -26px !important
            background-color: $light
            border: 0
            @include media-breakpoint-up(lg)
              border-left: 2px solid $gray-300
              border-bottom: 2px solid $gray-300
              border-right: 2px solid $gray-300

            ul.list-unstyled
              li
                a
                  color: $secondary
                  &:hover
                    background-color: $white
                    color: $penta-aqua

nav.navbar
  // z-index: 997
  .container

    a.navbar-brand
      svg
        height: 32px

    button.navbar-toggler
      position: relative
      height: 27px
      width: 32px
      border: 0
      span.icon-bar
        position: absolute
        left: 0
        height: 3px
        width: 32px
        // z-index: 9999
        background-color: $primary
        border-radius: 4px
        transition: all .4s cubic-bezier(.5,0,0,1)
        &:nth-of-type(1)
          top: 3px
        &:nth-of-type(2)
          top: 12px
        &:last-of-type
          bottom: 3px
      &[aria-expanded="true"]
        span.icon-bar
          &:nth-of-type(1)
            transform: rotate(45deg) translateX(6px) translateY(8px)
          &:nth-of-type(2)
            opacity: 0
            transform: translateX(-12px)
          &:nth-of-type(3)
            transform: rotate(-45deg) translateX(4px) translateY(-7px)

    .navbar-collapse
      ul.navbar-nav
        li.nav-item
          a.nav-link
            color: $primary
          &:hover, &.active
            a.nav-link
              color: $penta-aqua

      ul.navbar-nav
        li.nav-item
          a.nav-link
            outline: 0
            &.dropdown-toggle
              &::after
                display: none
              &:focus
                color: $penta-aqua
              i, svg
                transform: translateY(2px)
                transition: transform .2s ease-in-out
              &[aria-expanded="true"]
                i, svg
                  transform: translateY(2px) rotate(90deg)

        li.nav-item.dropdown
          .dropdown-menu
            transform: translateY(11px)
            background-color: $light
            border: 0
            @include media-breakpoint-up(lg)
              // Two columns:
              // transform: translateY(11px) translateX(-172px)
              transform: translateY(11px) translateX(-17px)
              border-left: 2px solid $gray-300
              border-bottom: 2px solid $gray-300
              border-right: 2px solid $gray-300

            ul.list-unstyled
              li
                a
                  color: $secondary
                  &:hover
                    background-color: $white
                    color: $penta-aqua

          &#i18n
            .dropdown-menu
              @include media-breakpoint-up(lg)
                transform: translateY(11px) translateX(-10px)

            li
              a
                color: $secondary
                &:hover
                  background-color: $white
                  color: $penta-aqua
