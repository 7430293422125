main.main#data-centers,
main.main#rechenzentren
  header.page-header
    background: rgb(52,76,100)
    background: linear-gradient(135deg, rgba(52,76,100,1) 0%, rgba(91,119,148,1) 100%)
    .container
      .row
        .col-8
          .card
            .card-footer
              a.btn
                position: relative
                padding-right: 32px
                background-color: #FFD133
                svg
                  position: absolute
                  @include calc(top, '50% - 8px')
                  right: 12px
                  transition: all .2s ease-in-out
                  &:last-of-type
                    opacity: 0
                    visibility: hidden
                &:hover
                  background-color: $white
                  svg
                    &:first-of-type
                      opacity: 0
                      visibility: hidden
                      transform: translateX(4px)
                    &:last-of-type
                      opacity: 1
                      visibility: visible
                      transform: translateX(4px)



  // section.country
    .container
      .row
        .col-lg-6
          &:first-of-type
            .card
              .card-body
                img.position-absolute
                  width: 36px
                  transition: all .2s ease-in-out
                  &[aria-expanded="true"],
                  &:hover
                    opacity: 100 !important
          &:nth-of-type(2)
            .card
              .accordion
                .accordion-item
                  border: 0
                  border-bottom: 1px solid rgba($secondary, .25)
                  h2.accordion-header
                    &:hover,
                    &.focus
                      button.accordion-button
                        background-color: #e6ebf4

    // &#germany
      .container .row .col-lg-6:first-of-type .card .card-body
        img.position-absolute
          &#berlin-marker
            top: 180px
            right: 120px
          &#cologne-marker
            top: 310px
            left: 130px
          &#dusseldorf-marker,
          &#duesseldorf-marker
            top: 280px
            left: 100px
          &#hamburg-marker
            top: 110px
            left: 270px
          &#leipzig-marker
            top: 270px
            right: 180px

    // &#denmark
      .container .row .col-lg-6:first-of-type .card .card-body
        img.position-absolute
          &#copenhagen-marker
            top: 380px
            right: 80px
