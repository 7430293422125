main.main#sustainability,
main.main#nachhaltigkeit,
main.main#developpement-durable

  header.page-header
    min-height: 480px
    background-image: url('/assets/img/sustainability/field-flowers.jpeg')
    @include background

  section#sustainability-mission
    background: linear-gradient(to bottom, #ffffff, #ffffff, #c2e3d0)
    // .container
      .row
        .col-lg-5
          .card
            .card-body
              ul.list-unstyled
                @include media-breakpoint-up(md)
                  columns: 2
                  -webkit-columns: 2
                  -moz-columns: 2

    img.position-absolute
      bottom: 0
      right: 0
      max-height: 176px
      @include media-breakpoint-up(md)
        max-height: 256px
