main.main#partner-program,
main.main#partner-programm

  section#channel-sales
    .container
      .row
        &:nth-of-type(2)
          .col-md-8
            .card
              .card-body
                ul.list-unstyled
                  @include media-breakpoint-up(md)
                    columns: 2
                    -webkit-columns: 2
                    -moz-columns: 2
