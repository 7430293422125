main.main#services
  section#services-grid
    .container
      .row

        @include media-breakpoint-up(md)
          display: -webkit-grid
          display: grid
          grid-rows: repeat(2, 1fr)
          -webkit-grid-rows: repeat(2, 1fr)
          grid-columns: repeat(2, 1fr)
          -webkit-grid-columns: repeat(2, 1fr)

        .col-12
          .card
            .card-header
              img.img-fluid
                max-width: 64px

            .card-body
              ul.list-unstyled
                @include media-breakpoint-up(md)
                  columns: 2
                  -webkit-columns: 2
                  -moz-columns: 2


          &:first-of-type
            @include media-breakpoint-up(md)
              grid-area: 1 / 1 / 2 / 2

          &:nth-of-type(2)
            @include media-breakpoint-up(md)
              grid-area: 1 / 2 / 2 / 3

          &:nth-of-type(3)
            @include media-breakpoint-up(md)
              grid-area: 2 / 1 / 3 / 2
