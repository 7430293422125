h1,
h2,
h3,
h4,
h5,
h6,
a.btn,
button
  font-family: 'proxima-nova'

p
  color: $secondary

a.btn,
button.btn
  position: relative
  svg
    position: absolute
    transition: all .2s ease-in-out
    &:last-of-type
      opacity: 0
      visibility: hidden
  &:hover
    svg
      &:first-of-type
        opacity: 0
        visibility: hidden
        transform: translateX(4px)
      &:last-of-type
        opacity: 1
        visibility: visible
        transform: translateX(4px)

  &.py-2
    padding-right: 32px
    svg
      right: 12px
      @include calc(top, '50% - 7px')

  &.py-1
    padding-right: 26px
    svg
      right: 12px
      @include calc(top, '50% - 5px')

  &.btn-primary
    background-color: #FFD133
    color: $secondary
    border: 1px solid #FFD133
    &:hover
      background-color: $white
      border: 1px solid $white

  &.btn-secondary
    background-color: $white
    color: $secondary
    border: 1px solid $secondary
    &:hover
      background: $light
      border: 1px solid $light

footer.footer
  .container

    .row:first-of-type
      .col-md-5
        .card
          p.card-text
            a
              text-decoration: none
              color: $white
              transition: all .2s ease-in-out
              &:hover
                color: $penta-aqua
      .col-12.col-md-3,
      .col-12.col-md-2
        .card
            li
              a
                text-decoration: none
                color: $white
                transition: all .2s ease-in-out
                &:hover
                  color: $penta-aqua
      .col-12.col-md-3
        .card
          ul.list-unstyled
            columns: 2
            -webkit-columns: 2
            -moz-columns: 2

    .row:nth-of-type(2)
      .col
        .card
          ul.list-unstyled
            li
              &:not(:last-of-type)::after
                @include media-breakpoint-up(md)
                  content: "|"
                  margin-left: 4px
                  margin-right: 4px

header.page-header
  min-height: 480px
  @include background
  .container:nth-of-type(2)
    .row
      .col-12
        .card
          .card-body
            nav
              ol.breadcrumb
                li
                  background-color: rgba($light, .5)
                  &:first-of-type
                    padding-left: 1rem
                    border-radius: 6px 0 0 0
                  &:last-of-type
                    padding-right: 1rem
                    border-radius: 0 6px 0 0
                  a
                    color: $secondary
                    font-weight: bold
                    transition: all .2s ease-in-out
                    &:hover
                      color: $gray-300
                  &.active
                    font-weight: bold
                    color: $gray-500

section
  .container
    .row#locations-row
      .col-md-4
        .card
          .accordion
            .accordion-item
              border: 0
              border-bottom: 1px solid rgba($secondary, .25)
              h2.accordion-header
                &:hover,
                &.focus
                  button.accordion-button
                    background-color: #e6ebf4
              .accordion-collapse
                .accordion-body
                  .row
                    .col-md-7
                      a.btn
                        position: relative
                        padding-right: 32px
                        background-color: #FFD133
                        svg
                          position: absolute
                          @include calc(top, '50% - 6px')
                          right: 12px
                          transition: all .2s ease-in-out
                          &:last-of-type
                            opacity: 0
                            visibility: hidden
                        &:hover
                          background-color: $white
                          svg
                            &:first-of-type
                              opacity: 0
                              visibility: hidden
                              transform: translateX(4px)
                            &:last-of-type
                              opacity: 1
                              visibility: visible
                              transform: translateX(4px)


      .col-md-8
        .card

          min-height: 576px
          .card-body
            .map-marker
              &[aria-expanded="true"],
              &:hover
                opacity: 100 !important


          // img.position-absolute
            height: 56px
            transition: all .2s ease-in-out
            &[aria-expanded="true"],
            &:hover
              opacity: 100 !important
