main.main.single-cases
  section
    .container
      .row
        .col-lg-9
          .card
            .card-body

              .text
                h3
                  color: $secondary
                  font-size: calc(1.275rem + .3vw)
                  @include media-breakpoint-up(xl)
                    font-size: 1.5rem
                  &:not(:first)
                    border: 1px solid orange

                ul
                  li
                    color: $secondary

              figure.mb-5
                @include media-breakpoint-up(lg)
                  width: 50%

              .card.quote
                @include media-breakpoint-up(lg)
                  width: 40%

                &.float-lg-none
                  @include media-breakpoint-up(lg)
                    width: 60%
                .card-body
                  svg.fa-quote-right
                    background: rgb(0,53,149)
                    background: linear-gradient(45deg, rgba(0,53,149,1) 35%, rgba(108,209,222,1) 100%)
                    width: 30px
                    height: 30px
                    border: 2px solid $gray-400
                    transform: translateY(-50%)
